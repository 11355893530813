// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import team1 from "assets/img/faces/avatar.jpg";
// import team3 from "assets/img/faces/kendall.jpg";
import team2 from "assets/img/faces/christian.jpg";
import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";
// nodejs library that concatenates classes
import classNames from "classnames";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";




const useStyles = makeStyles(styles);

export default function TeamSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>Our Founder</h2>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              {/* <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}> */}
              {/* <img src={team1} alt="..." className={imageClasses} /> */}
              {/* </GridItem> */}
              {/* <h4 className={classes.cardTitle}>
                Gigi Hadid
                <br />
                <small className={classes.smallTitle}>Model</small>
              </h4> */}
              <CardBody>
                {/* <p className={classes.description}>
                  You can write here details about one of your team members. You
                  can give more details about what they do. Feel free to add
                  some <a href="#pablo">links</a> for people to be able to
                  follow them outside the site.
                </p> */}
              </CardBody>
              {/* <CardFooter className={classes.justifyCenter}>
                <Button
                  justIcon
                  color="transparent"
                  className={classes.margin5}
                >
                  <i className={classes.socials + " fab fa-twitter"} />
                </Button>
                <Button
                  justIcon
                  color="transparent"
                  className={classes.margin5}
                >
                  <i className={classes.socials + " fab fa-instagram"} />
                </Button>
                <Button
                  justIcon
                  color="transparent"
                  className={classes.margin5}
                >
                  <i className={classes.socials + " fab fa-facebook"} />
                </Button>
              </CardFooter> */}
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <a href="/profile-page">
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={team2} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Ramon Yniguez
                <br />
                <small className={classes.smallTitle}>Founder</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                    Daring to dream, I deeply desired creating my own buisness yet, felt that something was missing
                    and after years of research and experience. I realized that, my very own experiences can be of use in helping
                    freelances and small business owners alike with the explicit interest in propeling thier business and internet presence.
                    So, using my experience created essential products, dedicated in helping others to dream just as big.
                    -- Ramon Y.
                    
                </p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <Button
                  justIcon
                  target="_blank"
                  color="transparent"
                  href="https://twitter.com/DreamIndieStud1?ref=dreamindiestud1"
                  className={classes.margin5}>
                  <i className={classes.socials + " fab fa-twitter"} />
                </Button>
                <Button
                  justIcon
                  color="transparent"
                  href="https://www.instagram.com/dreamindiestudios/"
                  target="_blank"
                  className={classes.margin5}>
                  <i className={classes.socials + " fab fa-instagram"} />
                </Button>
                <Button
                  justIcon
                  color="transparent"
                  href="https://www.facebook.com/DreamIndieStudio"
                  target="_blank"
                  className={classes.margin5}>
                  <i className={classes.socials + " fab fa-facebook"} />
                </Button>
              </CardFooter>
              </Card>
            </a>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              {/* <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}> */}
              {/* <img src={Qteam3} alt="..." className={imageClasses} /> */}
              {/* </GridItem> */}
              {/* <h4 className={classes.cardTitle}>
                Kendall Jenner
                <br />
                <small className={classes.smallTitle}>Model</small>
              </h4> */}
              <CardBody>
                {/* <p className={classes.description}>
                  You can write here details about one of your team members. You
                  can give more details about what they do. Feel free to add
                  some <a href="#pablo">links</a> for people to be able to
                  follow them outside the site.
                </p> */}
              </CardBody>
              {/* <CardFooter className={classes.justifyCenter}>
                <Button
                  justIcon
                  color="transparent"
                  className={classes.margin5}>
                  <i className={classes.socials + " fab fa-twitter"} />
                </Button>
                <Button
                  justIcon
                  color="transparent"
                  className={classes.margin5}>
                  <i className={classes.socials + " fab fa-instagram"} />
                </Button>
                <Button
                  justIcon
                  color="transparent"
                  className={classes.margin5}>
                  <i className={classes.socials + " fab fa-facebook"} />
                </Button>
              </CardFooter> */}
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
