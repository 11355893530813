import React, { useRef } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";
const jwt = require("jsonwebtoken");
const useStyles = makeStyles(styles);

const config = require("dotenv").config({ path: "../../../../.env" });
const configInit = require("dotenv-expand");
configInit(config);

export async function createToken(data) {
  
  return await jwt.sign(data, process.env.REACT_APP_JWTSECRET, {
    expiresIn: 86400, // 86400 expires in 24 hours
  });
}

export default function WorkSection() {
  const classes = useStyles();
  const valueRefName = useRef('');
  const valueRefEmail = useRef('');
  const valueRefMessage = useRef('');
  const contactUsRequest = async (e) => {
  e.preventDefault();
  const body = JSON.stringify({o: await createToken({
      full_name: valueRefName.current.value,
      email: valueRefEmail.current.value,
      message: valueRefMessage.current.value
    })});
    
    /** TODO: add custom logger here VIOLATION  */
    
    await fetch("https://www.dreamindiestudios.com/api/v1/users/signups", {
        headers: {
            'Content-Type': 'application/json'
        },
        method: 'POST',
        body
    }).then(response => {
        console.log("Response ", response);
        return response;
    }).catch(err => err);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>Work with us</h2>
          <h4 className={classes.description}>
            Provide details about your product or agency. Write a
            few lines about each one and contact us about any further
            collaboration. We will responde at the earliest convenience.
          </h4>
          <form id="contact-us" onSubmit={(e) => contactUsRequest(e)} method="POST" autoComplete="off">
            
           <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Your Name"
                  id="name"
                  formControlProps={{
                    required: true,
                    fullWidth: true
                  }}
                  inputProps={{
                    inputRef: valueRefName
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Your Email"
                  id="email"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    inputRef: valueRefEmail
                  }}
                />
              </GridItem>
              <CustomInput
                labelText="Your Message"
                id="message"
                formControlProps={{
                  fullWidth: true,
                  className: classes.textArea
                }}
                inputProps={{
                  inputRef: valueRefMessage,
                  required: true,
                  multiline: true,
                  rows: 5
                }}
              />
              <GridItem xs={12} sm={12} md={4}>
                <Button color="primary" type="submit">Send Message</Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
