import Backdrop from "@material-ui/core/Backdrop";
import Modal from "@material-ui/core/Modal";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
// nodejs library that concatenates classes
import classNames from "classnames";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import React from "react";
// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
import TeamSection from "./Sections/TeamSection.js";
import WorkSection from "./Sections/WorkSection.js";


const dashboardRoutes = [];

const useStyles = makeStyles(styles);
const dotenv = require("dotenv");
const dotenvExpand = require("dotenv-expand");

const appEnv = dotenv.config({ path: "../../../.env"});
dotenvExpand(appEnv);

// const latestYTBVideo = String(process.env.DREAM_INDIE_STUDIO_LT_YTB_VIDEO);

// function rand() {
//   return Math.round(Math.random() * 20) - 10;
// }

// function getModalStyle() {
//   // const top = 100 + rand();
//   // const left = 100 + rand();
//   return {
//     position: "absolute",
//     top: `50%`,
//     left: `50%`,
//     transform: `translate(-50%, -50%)`,
//     display: "inline-block",
//     "vertical-align": "middle",
//   };
// }

// const useModalStyles = makeStyles((theme) => ({
//   modal: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   },
//   paper: {
//     position: "absolute",
//     maxHeight: 450,
//     width: 550,
//     height: 550,
//     boxShadow: theme.shadows[5],
//     padding: theme.spacing(2, 4, 3),
//   },
  
// }));

export default function LandingPage(props) {
  const classes = useStyles();
  // const modalClass = useModalStyles();
  // const [modalStyle] = React.useState(getModalStyle);
  const { ...rest } = props;

  const [open, setOpen] = React.useState(false);

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };
  
  
  // const body = (
  //   <Fade in={open}>
  //        <GridContainer style={modalStyle} className={modalClass.paper} > 

  //         <GridItem xs={12} sm={12} md={6}>
  //           <iframe
  //            title="Dream Indie Studios LLC."
  //            width={height + "%"}
  //            height={width + "%"}
  //         src="https://www.youtube.com/embed/tgbNymZ7vqY?playlist=tgbNymZ7vqY&autoplay=1&loop=1" allowFullScreen></iframe>
  //         </GridItem>
  //       </GridContainer>
  //    </Fade>
  // );

  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="Dream Indie Studios LLC."
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/bg4.jpg")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>Dare to Dream</h1>
              <h4>
                We believe in a better tomorrow, and aspire to be the example
                that dreams do come true.{" "}
                <em>
                  SO,{" "}
                  <b>
                    <u>DREAM BIG!</u>
                  </b>
                </em>
              </h4>
              <br />
              {/* <Button color="danger" size="lg" onClick={handleOpen}>
                <i className="fas fa-play" />
                Watch video
              </Button> */}
            </GridItem>
          </GridContainer>
          <Modal
            open={open}
            className={classes.modal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            aria-labelledby="Dream Indie Studios LLC. Latest Video"
            aria-describedby="Dream Indie Studios LLC. Latest Video">
            {/* {body} */}
          </Modal>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProductSection />
          <TeamSection />
          <WorkSection />
        </div>
      </div>
      <Footer />
    </div>
  );
}
