import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import LocalGroceryStoreIcon from "@material-ui/icons/LocalGroceryStore";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Let{"'"}s talk product</h2>
          <h5 className={classes.description}>
            We provide a variety of products and services{", "}desiged to give
            you our customer a bigger bang for your buck{"."} Checkout out some
            of our industry leading products suited to take your company to the
            next level{"."}
            your company or services.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Free Live Chat"
              hrefLink="https://thefreelancecrm.com/liveChat"
              description="Interested in communicating with your customers in realtime ? With out Free Live Chat service get notified when you're site's visited or communicate with visitors interested in your company or service."
              icon={Chat}
              iconColor="info"
              vertical />
            </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Freelance CRM"
              hrefLink="https://thefreelancecrm.com"
              description="Checkout our flagship service product designed for aspiring entrepreneurs, and businesses alike focusing on scaling their online presence with our powerful tools"
              icon={VerifiedUser}
              iconColor="success"
              vertical />
            </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Fully Featured App Store"
              hrefLink="https://thefreelancecrm.com/appStore"
              description="Applications are essential for any business, visit our flagship product, which supports growing library of third-party products and services located in one store."
              icon={LocalGroceryStoreIcon}
              iconColor="danger"
              vertical />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
